// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_r3 d_gS d_cw d_dv";
export var quoteParagraphCenter = "x_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "x_gV d_gV d_cw d_dx";
export var quoteRowLeft = "x_r4 d_bG";
export var quoteRowCenter = "x_r5 d_bD";
export var quoteRowRight = "x_r6 d_bH";
export var quoteWrapper = "x_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "x_gR d_gR";
export var quoteExceptionSmall = "x_r7 y_r7";
export var quoteExceptionNormal = "x_r8 y_r8";
export var quoteExceptionLarge = "x_r9 y_r9";
export var quoteAuthorExceptionSmall = "x_sb y_sb";
export var quoteAuthorExceptionNormal = "x_sc y_sc";
export var quoteAuthorExceptionLarge = "x_sd y_sd";