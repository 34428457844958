// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_v0";
export var lbContainerInner = "D_v1";
export var movingForwards = "D_v2";
export var movingForwardsOther = "D_v3";
export var movingBackwards = "D_v4";
export var movingBackwardsOther = "D_v5";
export var lbImage = "D_v6";
export var lbOtherImage = "D_v7";
export var prevButton = "D_v8";
export var nextButton = "D_v9";
export var closing = "D_wb";
export var appear = "D_wc";