// extracted by mini-css-extract-plugin
export var tileContent = "v_rM d_w d_H d_Z";
export var teamTextLeft = "v_rN d_dv";
export var teamTextCenter = "v_rP d_dw";
export var teamTextRight = "v_rQ d_dx";
export var alignLeft = "v_qh d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "v_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "v_qj d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "v_rR d_dW";
export var teamContainerFull = "v_rS d_dT";
export var teamRowWrapper = "v_rT d_cc";
export var teamTileWrapper = "v_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "v_rV d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "v_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "v_j5 d_j5 d_Z";
export var teamHoverNoGutters = "v_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "v_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "v_rW d_j7";
export var teamInfoWrapperRound = "v_j7 d_j7";
export var teamInfoWrapper = "v_rX d_j8 d_0";
export var teamInfoWrapperNoGutters = "v_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "v_jZ d_jZ";
export var teamImgWrapperAlt = "v_j0 d_j0";
export var teamImgWrapperNoGutters = "v_kb d_kb";
export var teamHeader = "v_rY d_cw";
export var teamHeaderAlt = "v_rZ d_cw";
export var teamHeaderNoGutters = "v_r0 d_cw d_cD";