// extracted by mini-css-extract-plugin
export var carouselContainer = "C_qm d_w d_H d_bf d_Z";
export var carouselContainerCards = "C_vc C_qm d_w d_H d_bf d_Z";
export var carouselContainerSides = "C_vd d_w d_H d_Z";
export var prevCarouselItem = "C_vf d_w d_H d_0 d_k";
export var prevCarouselItemL = "C_vg C_vf d_w d_H d_0 d_k";
export var moveInFromLeft = "C_vh";
export var prevCarouselItemR = "C_vj C_vf d_w d_H d_0 d_k";
export var moveInFromRight = "C_vk";
export var selectedCarouselItem = "C_vl d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "C_vm C_vl d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "C_vn C_vl d_w d_H d_Z d_bf";
export var nextCarouselItem = "C_vp d_w d_H d_0 d_k";
export var nextCarouselItemL = "C_vq C_vp d_w d_H d_0 d_k";
export var nextCarouselItemR = "C_vr C_vp d_w d_H d_0 d_k";
export var arrowContainer = "C_vs d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "C_vt C_vs d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "C_vv C_vt C_vs d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "C_vw C_vs d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "C_vx";
export var nextArrowContainerHidden = "C_vy C_vw C_vs d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "C_kG d_0";
export var prevArrow = "C_vz C_kG d_0";
export var nextArrow = "C_vB C_kG d_0";
export var carouselIndicatorContainer = "C_vC d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "C_vD d_w d_bz d_bF";
export var carouselText = "C_vF d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "C_vG C_vF d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "C_vH d_b7";
export var carouselIndicator = "C_vJ C_vH d_b7";
export var carouselIndicatorSelected = "C_vK C_vH d_b7";
export var arrowsContainerTopRight = "C_vL d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "C_vM d_0 d_bl d_bC";
export var arrowsContainerSides = "C_vN d_0 d_bl d_bC";
export var smallArrowsBase = "C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "C_vQ C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "C_vR C_vQ C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "C_vS C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "C_vT C_vS C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "C_p9";
export var iconSmall = "C_vV";
export var multipleWrapper = "C_vW d_bC d_bF d_bf";
export var multipleImage = "C_vX d_bC";
export var sidesPrevContainer = "C_vY C_vQ C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "C_vZ C_vQ C_vP d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";